// Customised using https://github.com/twbs/bootstrap-npm-starter/blob/main/scss/starter.scss

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";


// Customize some defaults
$body-color: $light;
$body-bg: $dark;
$success: #7952b3;

@import "bootstrap/scss/bootstrap";

